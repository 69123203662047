import React from "react";
import useAuth from "../hooks/useAuth";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { read_cookie } from "sfcookies";
import { ToastLoad } from "./toast";

const RequireAuth = ({ allowedRoles }) => {
  var { auth } = useAuth();
  const location = useLocation();
  if (!Boolean(auth?.roles)) { 
    auth = read_cookie("auth");
  }
  return (
    // auth?.accessToken
    auth?.roles?.find((role) => allowedRoles?.includes(role)) != undefined ? (
      // IF TRUE
      <Outlet />
    ) : // If False
    auth?.accessToken ? (
      <Navigate to={"/unauthorised"} state={{ from: location }}  />
     
      
    ) : (
      <Navigate to={"/login"} state={{ from: location }} replace />
    )
  );
};

export default RequireAuth;
