import React, { createContext, useState, useEffect } from "react";
import { read_cookie } from "sfcookies";
import axios from "axios";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [cohorts_list, setCohorts_list] = useState([]);
  const [students, setStudents] = useState([]);
  const [bootcamp_list, setBootcamp_list] = useState([]);
  const [certificateStudents, setCertificateStudents] = useState({});

  // const API_URL = "https://lnd.iec.org.pk";
  const API_URL = "http://localhost:5000";  

  //New
  const [dropoutStudents, setDropoutStudents] = useState([]);

  const [studentproject, setStudentProject] = useState([]);

  const [studentsNarrative, setStudentsNarrative] = useState([]);

  const [instructorData, setInstructorData] = useState([]);

  const [CSVData, setCSVData] = useState([]);

  const [OneonOneSessionData, setOneonOnSessionData] = useState([]);

  useEffect(() => {
    setAuth(read_cookie("auth"));
    console.log("Auth Context: ", read_cookie("auth"));
  }, []);

  const refreshCohort = () => {
    axios.get(API_URL + "/api/get_cohorts").then((res) => {
      setCohorts_list(res.data.output);
      setStudents(res.data.students);
    });
  };

  useEffect(() => {
    axios.get(API_URL + "/api/get_cohorts").then((res) => {
      setCohorts_list(res.data.output);
      setStudents(res.data.students);
    });
  }, []);

  const [currentUserGrList, setCurrentUSerGrList] = useState([]);

  const DataRead = [
    {
      id: 1,
      week: 1,
      sessiondate: "2022-04-13",
      actualtopic: "Bootstrap CSS Components",
      topics_covered: "CSS components",
      videoref: "Project Planning and Working",
      videolink: "",
      shortproject:
        "https://www.w3schools.com/jsref/tryit.asp?filename=tryjsref_splice2",
      session_details: "Review",
      assignment: "Assigned",
      attendance: "Submitted",
      // bootcamp: "",
    },
    {
      id: 2,
      week: 2,
      sessiondate: "2022-04-13",
      actualtopic: "Bootstrap CSS Components",
      topics_covered: "Bootstrap components",
      videoref: "Project Planning and Working",
      videolink:
        "https://www.w3schools.com/jsref/tryit.asp?filename=tryjsref_splice2",
      shortproject: "",
      session_details: "Review",
      assignment: "Assigned",
      attendance: "Submitted",
      // bootcamp: "",
    },
  ];
  const [curriculum, setCurriculum] = useState(DataRead);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        cohorts_list,
        setCohorts_list,
        students,
        setStudents,
        API_URL,
        bootcamp_list,
        setBootcamp_list,
        refreshCohort,
        certificateStudents,
        setCertificateStudents,
        dropoutStudents,
        setDropoutStudents,
        studentproject,
        setStudentProject,
        studentsNarrative,
        setStudentsNarrative,
        instructorData,
        setInstructorData,
        OneonOneSessionData,
        setOneonOnSessionData,
        CSVData,
        setCSVData,
        currentUserGrList,
        setCurrentUSerGrList,
        setCurriculum,
        curriculum,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
