import React, {useRef} from 'react'
import {

    CToast,
    CToastHeader,
    CToastBody,
    CToaster
  
} from "@coreui/react";


export const ToastLoad = ({error , Message , login}) => {
  const toaster = useRef()
  return (
    <CToaster ref={toaster} 
    push={
      <CToast style={{backgroundColor:'#154C79',  color:'white'}}  >
        <CToastHeader style={{backgroundColor:'white'}}  closeButton>
          <strong  style={{color:'#154C79'}} className="me-auto"> {error ?  'Sorry' : login ? 'Un-Authorised' :'Congratulations!' } </strong>
        </CToastHeader> 
        
        <CToastBody> {Message} </CToastBody> 
      </CToast>
    } 
    placement="top-end" />
  );

}