import React, { Component } from "react";
// import { HashRouter, Route, Switch } from 'react-router-dom'
import "./scss/style.scss";
import { Routes, Route } from "react-router-dom";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
// import UserTable from "./components/User Table/UserTable";
// import Model from "./components/Model";
import RequireAuth from "./components/RequireAuth";

// import UnAuthorised from "./components/UnAuthorised";
// import StudentProfileTable from "./components/User Table/StudentProfileTable";
// import CertificateComponent from "./components/CertificateComponent";
// import CertificatePic from "./components/CertificatePic";
// import CertificationForm from "./components/CertificationForm";
// import DownloadCertificate from "./components/DownloadCertificate";
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages listed

const UserTable  = React.lazy(() => import('./components/User Table/UserTable'))
const Model = React.lazy(() => import('./components/Model'))
const UnAuthorised = React.lazy(() => import('./components/UnAuthorised'))

const StudentProfileTable = React.lazy(() => import('./components/User Table/StudentProfileTable'))

const CertificateComponent = React.lazy(() => import('./components/CertificateComponent'))
const CertificatePic = React.lazy(() => import('./components/CertificatePic'))
const CertificationForm = React.lazy(() => import('./components/CertificationForm'))
const DownloadCertificate = React.lazy(() => import('./components/DownloadCertificate'))
const Section = React.lazy(()=> import("./components/Attendance/Advisor/section"))
const Dropout = React.lazy(()=> import('./components/Attendance/Advisor/Dropout/dropOut'))
const ExploreStudent = React.lazy(()=> import('./components/Attendance/Advisor/explore_student'))
const PortfolioProject = React.lazy(()=> import('./components/Attendance/Advisor/PortfolioProject/portfolio_project'))
const NarrativeUpdate = React.lazy(()=> import('./components/Attendance/Advisor/NarrativeUpdate/narrative_update'))
const OneonOneSession = React.lazy(()=> import('./components/Attendance/Advisor/OneonOneSession/one_on_one'))
const InstructorSheet = React.lazy(()=> import('./components/Attendance/Advisor/InstructorSheet/instructor_sheet'))
const AdvisorSummary = React.lazy(()=>import('./components/Attendance/Advisor/Summary/advisorSummary'))
const Curriculum = React.lazy(()=> import('./components/Instructor/curriculum'))
const SearchAttendance= React.lazy(()=> import("./components/Attendance/Advisor/SearchAttendance/searchAttendance"))
const ProfessionalDevSession = React.lazy(()=>import("./components/Attendance/Advisor/DevSession/professionaldevsession"))
const AddCohort = React.lazy(()=> import("./components/SuperAdmin/addcohort"))
const NewStudentsCSV= React.lazy(()=> import("./components/SuperAdmin/newStudentsCSV"))
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const AllUsers = React.lazy(()=> import('./components/AllUsers'))
const AddUser = React.lazy(() => import('./components/AddUser'))
const PortfolioExcel = React.lazy(()=> import("./components/Attendance/Advisor/PortfolioProject/portfolioexcel"))

class App extends Component {
  render() {
    return (
      <React.Suspense fallback={loading}>  
        <Routes>

          <Route path="/" name="Home" element={<DefaultLayout />}>
            <Route path="/" element={<StudentProfileTable />} />
            <Route path="/certificationform" element={<CertificationForm />} />
            <Route path="/certificate" element={<CertificateComponent />} />
            
            <Route element={<RequireAuth allowedRoles={[true, false]} />}>
            <Route path="/attendance" element={<Section />} />
              <Route path="/dropout" element={<Dropout />} />
             <Route path="/explore" element={<ExploreStudent />} />
             <Route path="/portfolio" element={<PortfolioProject />} />
             <Route path="/portfolioexcel" element={<PortfolioExcel />} />
             <Route path="/narrative" element={<NarrativeUpdate />} />
             <Route path="/onesession" element={<OneonOneSession />} />
             <Route path="/instructor" element={<InstructorSheet />} /> 
             <Route path="/advisorsummary" element={<AdvisorSummary />} /> 
             <Route path="/curriculum" element={<Curriculum />} /> 
             <Route path="/searchattendance" element={<SearchAttendance />} /> 
             <Route path="/devsession" element={<ProfessionalDevSession />} /> 
             <Route path="/all_users" element={<AllUsers />} />
             <Route path="/add_user" element={<AddUser />} />
             <Route path="/create_cohort" element={<AddCohort />} />
             <Route path="/new_Students_csv" element={<NewStudentsCSV />} />
            </Route>

             
            <Route element={<RequireAuth allowedRoles={[true] } />}>
              <Route path="/dashboard" element={<Model />} />
              
            </Route>
          </Route>

          {/* <Route
            exact
            path="/attendance"
            // name="Attendance"
            element={<Attendance />}
          /> */}

          <Route path="/verify/:id" element={<DownloadCertificate />} />
          {/* <Route path="/verify/:id" element={<CertificatePic />} /> */}
          
          {/* <Route path="/certificatePic" element={<CertificatePic />} />
 */}


          {/* <Route path="/downloadcertificate" element={<DownloadCertificate />} /> */}
          <Route path="/progress" element={<UserTable />} />

         
          {/* <Route exact path="/progress" name='Progress' element={<UserTable />} /> */}

          <Route
            exact
            path="/login"
            name="Login Page"
            element={<Login />}
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            element={<Register />}
          />
          <Route path="/unauthorised" element={<UnAuthorised />}></Route>
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
        </Routes>
      </React.Suspense>
    );
  }
}

export default App;
